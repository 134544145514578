@import '/public/styles/core';

.ChartContainer {
  padding-bottom: 1.5rem;
  height: 100%;
}

// Chart wrap
.UsersChartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 1.5rem;

  @include from-mq-sm {
    flex-direction: row;
    gap: 4rem;
    justify-content: space-between;
  }
}

// Chart
.CircleContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

.CircleInner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.NumbersContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;

  .Counter span {
    font-size: 2.25rem !important;
  }

  .Label {
    display: none;

    span {
      color: #7a7a7a;
    }

    @include from-mq-lg {
      display: block;
    }
  }
}

// Chart details
.UserDetailsContainer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  @include from-mq-sm {
    gap: 1rem;
    justify-content: center;
  }
}

.Details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;

  .Badge {
    width: 10px;
    height: 10px;

    &.Registered {
      background: #29a072;
    }

    &.Unregistered {
      background: #282828;
    }

    &.Available {
      background: #e6e6e6;
    }
  }
}

.UserCount {
  margin-left: auto;
  flex-shrink: 0;

  span {
    color: #7a7a7a;
  }
}

.TimeRangeDropdown {
  position: relative;
  margin-bottom: 1.5rem;
  width: 132px;
}

.UserBar {
  position: relative;
  margin-bottom: 1rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;

  @include from-mq-xl {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.MostActive {
    background-color: rgb(30 215 150 / 10%);

    .ProgressBar {
      background-color: #1ed796;
    }
  }

  &.LeastActive {
    background-color: rgb(240 190 10 / 10%);

    .ProgressBar {
      background-color: #f0be0a;
    }
  }
}

// UserData
.UserDataContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.UserName {
  @include text-overflow;
}

.LessonsCount {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-shrink: 0;
}

.ProgressBar {
  position: absolute;
  top: 0;
  left: 1px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  z-index: 0;
}

.Statuses {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.Status {
  padding: 0.5rem;

  .Badge {
    display: inline-block;
    margin-right: 8px;
    width: 10px;
    height: 10px;

    &.NotStarted {
      background: #e6e6e6;
    }

    &.InProgress {
      background: #f0be0a;
    }

    &.Complete {
      background: #29a072;
    }
  }
}

.CourseContainer {
  margin-bottom: 1.5rem;
}

.CombinedBars {
  display: flex;
  margin-bottom: 0.5rem;
}

.Bar {
  height: 2rem;

  &.NotStarted {
    border-radius: 4px 0 0 4px;
    background-color: #e6e6e6;
  }

  &.InProgress {
    background-color: #f0be0a;
  }

  &.Complete {
    border-radius: 0 4px 4px 0;
    background-color: #29a072;
  }
}

.UserLabels {
  display: flex;
  gap: 1rem;
}

.UserLabel {
  width: 20%;
}

.ProgressBarTooltip {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  .Total span {
    color: #7a7a7a;
  }
}

// stylelint-disable property-case, selector-pseudo-class-no-unknown, property-no-unknown
:export {
  primaryRed: #eb1414;
  primaryGreen: #29a072;
  primaryBlack: #282828;
  primaryWhite: #e6e6e6;
}

.AnalyticsChartCard {
  height: 100%;
  border-radius: var(--vfq-card-wrapper-border-radius-md);
}

.AnalyticsChartCardTitle {
  margin-bottom: 0.25rem;
}
