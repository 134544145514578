.LoadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  width: 100%;
  height: 100%;

  .ids-spinner label {
    font-size: 0.875rem;
  }
}
